import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiExpandoText } from '../src/';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "uiexpandotext"
    }}>{`UiExpandoText`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/expando/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`This component renders a text and an icon that when expanded reveals some content underneath it.`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/icons @uireact/expando`}</p>
    </blockquote>
    <h2 {...{
      "id": "just-a-expando"
    }}>{`Just a expando`}</h2>
    <Playground __position={1} __code={'<UiExpandoText expandLabel=\"Expand me\" collapseLabel=\"Collapse me\">\n  <span>This is some content</span>\n</UiExpandoText>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiExpandoText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiExpandoText expandLabel="Expand me" collapseLabel="Collapse me" mdxType="UiExpandoText">
    <span>This is some content</span>
  </UiExpandoText>
    </Playground>
    <h2 {...{
      "id": "expando-with-custom-category-and-size"
    }}>{`Expando with custom category and size`}</h2>
    <Playground __position={2} __code={'<UiExpandoText\n  expandLabel=\"Expand me large\"\n  collapseLabel=\"Collapse me large\"\n  category=\"positive\"\n  size=\"xlarge\"\n>\n  <span>This is some content</span>\n</UiExpandoText>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiExpandoText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiExpandoText expandLabel="Expand me large" collapseLabel="Collapse me large" category="positive" size="xlarge" mdxType="UiExpandoText">
    <span>This is some content</span>
  </UiExpandoText>
    </Playground>
    <h2 {...{
      "id": "expando-initiated-expanded"
    }}>{`Expando initiated expanded`}</h2>
    <Playground __position={3} __code={'<UiExpandoText expandLabel=\"Expand me\" collapseLabel=\"Collapse me\" expanded>\n  <span>This is some content</span>\n</UiExpandoText>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiExpandoText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiExpandoText expandLabel="Expand me" collapseLabel="Collapse me" expanded mdxType="UiExpandoText">
    <span>This is some content</span>
  </UiExpandoText>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiExpandoText} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      